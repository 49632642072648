import React, { useEffect, useState } from "react";
import { INetworkType, Network } from "../../neo/network";
// import RuneLoading from "./RuneLoading";
import { FaExclamationCircle, FaQuestion } from "react-icons/fa";
import { TESTNET } from "../../neo/consts";
import CubeLoading from "../../../ui/components/CubeLoading";
import { BoyzContract } from "../../neo/contracts/ftw/boyz";

interface IAfterTransactionSubmittedProps {
  txid: string;
  network: INetworkType;
  onSuccess: () => void;
  onError: () => void;
}

const boxStyle = {
  width: "300px",
  height: "300px",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const AfterTransactionSubmitted = ({
  txid,
  network,
  onSuccess,
  onError,
}: IAfterTransactionSubmittedProps) => {
  const [txSubmitted, setTxSubmitted] = useState(false);
  const [properties, setProperties] = useState<any>(undefined);
  const [hasError, setError] = useState("");

  useEffect(() => {
    async function checkTxid() {
      try {
        const res = await Network.getRawTx(txid, network);
        if (res) {
          setTxSubmitted(true);

          let orderNo;

          if (res && res.executions && res.executions.length > 0) {
            res.executions.forEach((i) => {
              if (i.notifications && i.notifications.length > 0) {
                i.notifications.forEach((n) => {
                  if (n.eventname === "Order") {
                    orderNo = n.state.value[1].value;
                  }
                });
              }
            });
          }

          if (orderNo) {
						let boy;
            do {
              try {
                boy = await new BoyzContract(network).getProperties(
                  `Boy_${orderNo}`
                );
                if (boy) {
									console.log(boy);
                  setProperties(boy);
                }
              } catch (e) {
                await Network.sleep(Network.READ_LOG_FREQUENCY);
              }
            } while (!boy);
          }
        }
      } catch (e: any) {
        console.log(e);
        setError(e.message);
      }
    }
    checkTxid();
  }, [txid]);

  return (
    <div className="has-text-centered">

      {hasError ? (
        <>
          <div style={boxStyle}>
            <FaExclamationCircle size={100} className="has-text-danger" />
          </div>
          <h1 className="title is-5 is-spaced">Error occurred</h1>
          <p className="subtitle is-6">
            {" "}
            <a
              target="_blank"
              href={`https://${
                network === TESTNET ? "testmagnet." : ""
              }explorer.onegate.space/transactionInfo/${txid}`}
            >
              View txid on explorer
            </a>
          </p>
          <button onClick={onError} className="button is-black">
            Close
          </button>
        </>
      ) : txSubmitted ? (
        properties ? (
          <>
            <div style={boxStyle}>
              <img height="100%" src={properties.image} />
            </div>
	          <div className={"mt-4"}>
		          <h1 className="title is-5 is-spaced">{properties.name}</h1>
		          <p className="subtitle is-6">Welcome to the Neo Boyz Club!</p>
		          <button onClick={onError} className="button is-black">
			          Close
		          </button>
	          </div>

          </>
        ) : (
          <>
            <div style={boxStyle}>
              <FaQuestion size={100} className="has-text-primary" />
            </div>
            <h1 className="title is-5 is-spaced">Waiting Oracle</h1>
            <p className="subtitle is-6">
              We are calling your Neo Boy from the block
            </p>
          </>
        )
      ) : (
        <>
          <div style={boxStyle}>
            <CubeLoading />
          </div>
          <h1 className="title is-5 is-spaced">Submitting</h1>
          <p className="subtitle is-6">
            Please wait until your transaction accepted
          </p>
        </>
      )}
    </div>
  );
};

export default AfterTransactionSubmitted;
