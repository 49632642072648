import {CONST as NEON_CONST} from "@cityofzion/neon-core";
import {MAINNET, PRIVATENET, TESTNET} from "./index";

/* Contract hashes */
export const GAS_SCRIPT_HASH = NEON_CONST.NATIVE_CONTRACT_HASH.GasToken;
export const NEO_SCRIPT_HASH = NEON_CONST.NATIVE_CONTRACT_HASH.NeoToken;
export const USDT_SCRIPT_HASH = "cd48b160c1bbc9d74997b803b9a7ad50a4bef020";
export const FWBTC_SCRIPT_HASH = "d6abe115ecb75e1fa0b42f5e85934ce8c1ae2893"
export const FWETH_SCRIPT_HASH = "c14b601252aa5dfa6166cf35fe5ccd2e35f3fdf5"

/* TTM HASHES */
export const WATT_SCRIPT_HASH = "9808fbad45ebca4abe7b4adf1fd4e1f399d0cc22";
export const LITH_SCRIPT_HASH = "4b9006e32173ba357b0696a492b85b85b2016ac4";
export const MAG_SCRIPT_HASH = "19557ae882c5007d5fd3634cd499387b8441360c";
export const HIST_SCRIPT_HASH = "af348a1ff998291cbf207fd6ba342da74593599f";
export const TGAS_SCRIPT_HASH = "56199aa066633745de4d603e6477881455c08243";
export const TTM_SCRIPT_HASH = "c0283310a5117b9d007941e8a0dc3dae9593f65c";

export const FTW_SCRIPT_HASH = {
  [PRIVATENET]: "c1a56650f12420405c5b7e2634eb3779a0c3e396",
  [TESTNET]: "c1a56650f12420405c5b7e2634eb3779a0c3e396",
  [MAINNET]: "9f8b20c31bb9e45003f2d9f316d2caf1dcd1bf20",
};

export const BNEO_SCRIPT_HASH = {
	[PRIVATENET]: "",
	[TESTNET]: "85deac50febfd93988d3f391dea54e8289e43e9e",
	[MAINNET]: "48c40d4666f93408be1bef038b6722404d9a4c2a",
};

export const NEP_SCRIPT_HASH = {
	[PRIVATENET]: "",
	[TESTNET]: "6f50289324428858794b384b2d57dce49959b95f",
	[MAINNET]: "f853a98ac55a756ae42379a312d55ddfdf7c8514",
};

export const LRB_SCRIPT_HASH = {
  [PRIVATENET]: "569ab5968f695a13947a2393b3297d520d951243",
  [TESTNET]: "569ab5968f695a13947a2393b3297d520d951243",
  [MAINNET]: "8c07b4c9f5bc170a3922eac4f5bb7ef17b0acc8b",
};
export const FLM_SCRIPT_HASH = {
  [PRIVATENET]: "1415ab3b409a95555b77bc4ab6a7d9d7be0eddbd",
  [TESTNET]: "1415ab3b409a95555b77bc4ab6a7d9d7be0eddbd",
  [MAINNET]: "f0151f528127558851b39c2cd8aa47da7418ab28",
};
export const GM_SCRIPT_HASH = {
  [PRIVATENET]: "047dbe376096643d7a93609388cde7f84ec28c52",
  [TESTNET]: "047dbe376096643d7a93609388cde7f84ec28c52",
  [MAINNET]: "9b049f1283515eef1d3f6ac610e1595ed25ca3e9",
};

/* Smith contracts */
export const NUDES_SCRIPT_HASH = "340720c7107ef5721e44ed2ea8e314cce5c130fa"
export const MAXI_SCRIPT_HASH = "5afb6804ee3598a58f8a0994b1df99d8be43a313";
export const TED_SCRIPT_HASH = "50b41a55c1d746eec2b86b8f0405fb49fbb96492";
export const N3F_SCRIPT_HASH = "979b839648d215fe895d559019dedde31fcf45a9";
export const FRANK_SCRIPT_HASH = "a06cfd7ae9dd7befb7bf8e5b8c5902c969182de0"
export const HOOD_SCRIPT_HASH = "c8d56cac2dd82e2da605ccae6865a99da491b97e";
export const B$_SCRIPT_HASH = "3782720ad22ecfdd26cc99135dbc6739a47a38c9";
export const GLC_SCRIPT_HASH = "77dc8cadcaef3694ab0f23901d8d9d825b19e6a2";
