import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { HOME_PATH } from "../consts";
import { WalletContextProvider } from "../packages/provider";
import WalletSidebar from "./components/WalletSidebar";
import { Toaster } from "react-hot-toast";
import Boyz from "./pages/Boyz";


const App = () => {
  return (
    <WalletContextProvider
      options={{
        useLocalStorage: true,
        useDevWallet: process.env.NODE_ENV === "development",
      }}
    >
      <Router>
        <Toaster position="bottom-center" />
	      <div className="is-relative" style={{zIndex: 1}}>
		      <div className="section">
			      <div className="columns is-centered">
				      <div className="column is-half">
					      <Route exact path={HOME_PATH} component={Boyz} />
				      </div>
			      </div>
		      </div>
		      <div
			      style={{
				      width: "100%",
				      height: "150px",
				      bottom: 0,
				      left: 0,
				      background: `url("/bgs/matrtix.png")`,
				      backgroundPosition: "top",
				      position: "absolute",
				      zIndex: -1
			      }}
		      ></div>
	      </div>
        <WalletSidebar />
      </Router>
    </WalletContextProvider>
  );
};

export default App;
