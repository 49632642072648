import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useWallet } from "../../../packages/provider";
import SocialLinkGroup from "../../components/SocialLinkGroup";
import { FaPowerOff } from "react-icons/fa";
import WalletDropdown from "../../components/WalletDropdown";
import { useApp } from "../../../common/hooks/use-app";
import { BoyzContract } from "../../../packages/neo/contracts/ftw/boyz";
import Modal from "../../components/Modal";
import AfterTransactionSubmitted from "../../../packages/ui/AfterTransactionSubmitted";
import toast from "react-hot-toast";

const samples = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];
const Boyz = () => {
  const [txid, setTxid] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { connectedWallet, network } = useWallet();
  const { toggleWalletSidebar } = useApp();
  const [totalSupply, setTotalSupply] = useState<number | undefined>(undefined);

  useEffect(() => {
    async function fetchContractStatus() {
      const res = await new BoyzContract(network).totalSupply();
      if (res) {
        setTotalSupply(res);
      }
    }
    fetchContractStatus();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const handleMint = async () => {
    if (connectedWallet) {
      try {
        const txid = await new BoyzContract(network).mint(connectedWallet);
        setTxid(txid);
      } catch (e: any) {
        toast.error(e.message);
      }
    } else {
      toggleWalletSidebar();
    }
  };

  return (
    <>
      <div
        className="media block"
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="media-content">
          <h1 className="title has-text-primary">Neo Boyz</h1>
          <p className="subtitle is-5 has-text-white">
            1111 unique collectible characters stored on Neo blockchain
            <br />
            <a
              target="_blank"
              href="https://medium.com/@Forthewin_network/neo-boyz-infiltrate-chain-e2ee433da3b"
              className="is-size-6 has-text-primary"
            >
              <small>[Read more]</small>
            </a>
          </p>
        </div>

        <div className="media-right">
          <div className="buttons">
            {connectedWallet ? (
              <WalletDropdown connectedWallet={connectedWallet} />
            ) : (
              <button
                onClick={toggleWalletSidebar}
                className="button is-black is-rounded"
              >
                <FaPowerOff className="has-text-primary" />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="block">
        <Slider {...settings}>
          {samples.map((img) => {
            return <img key={img} src={`/boyz/${img}.png`} />;
          })}
        </Slider>
      </div>

      <div className="block has-text-centered">
        <div style={{ height: "50px" }}>
          {totalSupply ? (
            <div className="has-text-grey-light">{totalSupply} / 1111</div>
          ) : (
            <div></div>
          )}
        </div>
        <button
          disabled={totalSupply === 1111}
          onClick={handleMint}
          className="button is-primary is-outlined"
        >
          {!totalSupply
            ? "Mint a Neo Boy"
            : totalSupply < 1111
            ? "Mint a Neo Boy"
            : "Sold out"}
        </button>
      </div>

      <div className="block has-text-centered has-text-white">
        <div className="columns is-mobile">
          <div className="column">
            <div className="title is-6 has-text-white">Blockchain</div>
            <p>Neo N3</p>
          </div>
          <div className="column">
            <div className="title is-6 has-text-white">Total supply</div>
            <p>1111 Boyz</p>
          </div>
          <div className="column">
            <div className="title is-6 has-text-white">Mint price</div>
            <p>5000 NEP</p>
          </div>
        </div>
      </div>

      <div className="block has-text-centered has-text-white">
        <div className="columns is-mobile">
          <div className="column">
            <div className="title is-6 has-text-white">Market</div>
            <a
              href="https://ghostmarket.io/collection/neo-boyz/"
              className="has-text-primary"
              target="_blank"
            >
              Ghost Market
            </a>
          </div>
          <div className="column">
            <div className="title is-6 has-text-white">Buy $NEP</div>
            <a
              target="_blank"
              href="https://www.forthewin.network/#/swap?tokenA=d2a4cff31913016155e38e474a2c06d08be276cf&tokenB=f853a98ac55a756ae42379a312d55ddfdf7c8514"
              className="has-text-primary"
            >
              FTW Swap
            </a>
          </div>
          <div className="column">
            <div className="title is-6 has-text-white">Rarity</div>
            <a
              target="_blank"
              href="https://www.forthewin.network/#/NFT/neo-boyz"
              className="has-text-primary"
            >
              Rarity filter
            </a>
          </div>
        </div>
      </div>

      <div className="block has-text-centered">
        <div className="title is-6 has-text-white">News</div>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://medium.com/@Forthewin_network/an-update-on-neo-boyz-afc89d501ba7"
              className="has-text-primary"
            >
              • An Update on “Neo Boyz”
            </a>
          </li>
	        <li>
		        <a
			        target="_blank"
			        href="https://lounge3.xyz/proposal/36"
			        className="has-text-primary"
		        >
			        • Give Neo Boyz a special ability
		        </a>
	        </li>

        </ul>
      </div>

      <div className="block has-text-centered">
        <div className="title is-6 has-text-white">Links</div>
        <SocialLinkGroup />
      </div>

      <div className="has-text-centered">
        <div className="title is-6 has-text-white">Created by</div>
        <a
          target="_blank"
          href="https://www.forthewin.network"
          className="has-text-white has-text-weight-bold"
        >
          Forthewin Network
        </a>
      </div>

      {txid && (
        <Modal onClose={() => setTxid("")}>
          <AfterTransactionSubmitted
            txid={txid}
            network={network}
            onSuccess={() => {
              setTxid("");
            }}
            onError={() => setTxid("")}
          />
        </Modal>
      )}
    </>
  );
};

export default Boyz;
