import React from "react";
import { FaDiscord, FaMedium, FaTwitter, FaGithub } from "react-icons/fa";

const SocialLinkGroup = () => {
  return (
    <>
	    <a
		    target="_blank"
		    href="https://discord.gg/A83mtQqsfP"
		    className="button is-black"
	    >
		    <FaDiscord />
	    </a>
      <a
        target="_blank"
        href="https://twitter.com/NeoBoyzFTW"
        className="button is-black"
      >
        <FaTwitter />
      </a>
      <a
        target="_blank"
        href="https://github.com/ForTheWinn"
        className="button is-black"
      >
        <FaGithub />
      </a>
      <a
        target="_blank"
        href="https://medium.com/@Forthewin_network"
        className="button is-black"
      >
        <FaMedium />
      </a>
    </>
  );
};

export default SocialLinkGroup;
