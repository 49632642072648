import { wallet as NeonWallet } from "@cityofzion/neon-core";
import { DEFAULT_WITNESS_SCOPE } from "../../../consts";
import { INetworkType, Network } from "../../../network";
import { IConnectedWallet } from "../../../wallet/interfaces";
import { wallet } from "../../../index";
import { NEP_SCRIPT_HASH } from "../../../consts/nep17-list";
import { BOYZ_SCRIPT_HASH } from "./consts";
import { parseMapValue } from "../../../utils";

export class BoyzContract {
  network: INetworkType;
  contractHash: string;

  constructor(networkType: INetworkType) {
    this.network = networkType;
    this.contractHash = BOYZ_SCRIPT_HASH[networkType];
  }

  mint = async (connectedWallet: IConnectedWallet): Promise<string> => {
    const senderHash = NeonWallet.getScriptHashFromAddress(
      connectedWallet.account.address
    );

    const balanceRes = await Network.read(this.network, [
      {
        operation: "balanceOf",
        scriptHash: NEP_SCRIPT_HASH[this.network],
        args: [
          {
            type: "Hash160",
            value: senderHash,
          },
        ],
      },
    ]);

		let userNEPBalance = 0;

		if(balanceRes && balanceRes.stack[0]){
			userNEPBalance = parseFloat(balanceRes.stack[0].value as string);
		}

		if(userNEPBalance < 500000000000){
			throw new Error("You don't have enough NEP")
		}

    const invokeScript = {
      operation: "transfer",
      scriptHash: NEP_SCRIPT_HASH[this.network],
      args: [
        {
          type: "Hash160",
          value: senderHash,
        },
        {
          type: "Hash160",
          value: this.contractHash,
        },
        {
          type: "Integer",
          value: 5000_00000000,
        },
        {
          type: "Integer",
          value: 50000000,
        },
      ],
      signers: [DEFAULT_WITNESS_SCOPE(senderHash)],
    };
    return wallet.WalletAPI.invoke(connectedWallet, this.network, invokeScript);
  };

	totalSupply = async (): Promise<number | undefined> => {
		const script = {
			scriptHash: this.contractHash,
			operation: "totalSupply",
			args: [],
		};
		const res = await Network.read(this.network, [script]);
		if (res.state === "FAULT") {
			return undefined;
		}
		return parseFloat(res.stack[0].value as string);
	};

  getProperties = async (tokenId: string): Promise<any> => {
    const script = {
      scriptHash: this.contractHash,
      operation: "properties",
      args: [
        {
          type: "String",
          value: tokenId,
        },
      ],
    };
    const res = await Network.read(this.network, [script]);
    if (res.state === "FAULT") {
      throw new Error("Failed");
    }
    return parseMapValue(res.stack[0] as any);
  };
}
